.w-pc-banner-screen {
    /* background-color: rgb(226, 226, 226); */
    height: 60vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-banner-screen>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-banner-screen>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(95, 199, 255);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-banner-screen>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-banner-screen>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-banner-screen>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    background-color: rgba(0, 0, 0, 0.36);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    backdrop-filter: blur(3px);
}

.w-pc-banner-screen>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}
.w-pc-banner-screen>.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25vh;
}
.w-pc-banner-screen>.wrapper>.content.display>h1{
    text-align: center;
        font-size: 6vw;
        font-family: 'CustomPoppinsBold';
        color: #fff;
}


 /* mobile */
 .w-mob-banner-screen {
     /* background-color: rgb(226, 226, 226); */
     height: 60vh;
     width: 100%;
     box-sizing: border-box;

 }

 .w-mob-banner-screen>.wrapper {
     height: 100%;
     width: 100%;
     /* background-color: wheat; */
     position: relative;
 }

 .w-mob-banner-screen>.wrapper>.background {
     position: absolute;
     top: 0;
     left: 0;
     background-color: rgb(95, 199, 255);
     height: 100%;
     width: 100%;
     box-sizing: border-box;
     z-index: 101;
 }

 .w-mob-banner-screen>.wrapper>.background>.frame {
     height: 100%;
     width: 100%;
     overflow: hidden;

     /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center; */
 }

 .w-mob-banner-screen>.wrapper>.background>.frame>img {
    height: 100%;
 }

 .w-mob-banner-screen>.wrapper>.shield {
     position: absolute;
     top: 0;
     left: 0;
     /* background-color: salmon; */
     background-color: rgba(0, 0, 0, 0.36);
     height: 100%;
     width: 100%;
     box-sizing: border-box;
     z-index: 102;
     backdrop-filter: blur(3px);
 }

 .w-mob-banner-screen>.wrapper>.content {
     position: absolute;
     top: 0;
     left: 0;
     /* background-color: cadetblue; */
     height: 100%;
     width: 100%;
     box-sizing: border-box;
     z-index: 102;
 }

 .w-mob-banner-screen>.wrapper>.content.display {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding-top: 25vh;
 }

 .w-mob-banner-screen>.wrapper>.content.display>h1 {
     text-align: center;
     font-size: 10vw;
     font-family: 'CustomPoppinsBold';
     color: #fff;
 }