.w-pc-career-list-screen{ 
    /* height: 100vh; */
    width: 100%;
    position: relative;
}
.w-pc-career-list-screen>.wrapper{
    height: 100%;
    width: 100%;
    position: relative;
}
.w-pc-career-list-screen>.wrapper>*{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.w-pc-career-list-screen>.wrapper>.background{
    overflow: hidden;
    background-image: url('../../assets/img/career/career.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
}
.w-pc-career-list-screen>.wrapper>.background>img{
    width: 100%;
}

.w-pc-career-list-screen>.wrapper>.shield{
    background-color: black;
    opacity: 36%;
}

.w-pc-career-list-screen>.wrapper>.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    color: #fff;
    
}
.w-pc-career-list-screen>.wrapper>.content>.screen{
    /* border: 1px solid red; */
    height: 100%;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.tagline{
    /* height: 100%; */
    height: 60vh;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 15vh;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.tagline>h1{
    font-size: 3.5vw;
    font-weight: bolder;
    width: 60%;
    text-align: center;
    font-family: 'CustomPoppinsBold';
    line-height: 130%;
}

.w-pc-career-list-screen>.wrapper>.content>.screen.form {
    height: 100%;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* border: 1px solid red; */
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form {
    display: flex;
    flex-direction: column;
    align-items:start;
    justify-content: center;
    background-color: #043639a3;
        backdrop-filter: blur(8px);
    height: 40vh;
    width: 90%;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 3vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form>h2{
    
    font-size: 4vh;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form>p{
    margin-bottom: 2vh;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form>input{
    border: none;
    outline: none;
    border-radius: 10px;
    height: 6.9vh;
    margin-block: 1vh;
    padding-inline: 1.3vh;
    padding-block: 0vh;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form>input.position{
    width: 15vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form>input.dream-job {
    width: 23vw;
}

.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #043639a3;
    backdrop-filter: blur(8px);
    /* height: 40vh; */
    width: 90%;
    border-radius: 10px;
    box-sizing: border-box;
    padding-inline: 3vw;
    padding-block: 3vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section{
    width: 100%;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-header{
    /* border: 1px solid red; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-bottom: 1vw;
    width: 100%;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-header>h3{
    font-family: 'CustomPoppinsBold';
    font-size: 2.2vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1{
    /* border: 1px solid red; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1vw;
    width: 100%;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1>.form-group{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1>.form-group.location{
    padding-right: 0.5vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1>.form-group.division{
    padding-left: 0.5vw;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1>.form-group>select{
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    padding: 1vw;
    margin: 0;
    color: rgb(19, 19, 19);
    font-size: 0.9vw;
    width: 100%;
}
/* .w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-1>.form-group>#division>option{
    color: red ;
    font-size: 12px;
} */
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2{
    /* border: 1px solid red; */
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2>.form-group.roles{
    box-sizing: border-box;
    /* padding-bottom: 1vw; */
    width: 78%;
    /* border: 1px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2>.form-group.roles>input{
    border: 0;
    padding-block: 1vw;
    padding-inline: 0.6vw;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;

    
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2 {
    /* border: 1px solid red; */
    box-sizing: border-box;
}

.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2>button{
    border: none;
    /* border-radius: 5px; */
    outline: none;
    cursor: pointer;
    padding-block: 0.8vw;
    padding-inline: 0.7vw;
    width: 22%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: 0.3s ease-out;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-2>button:hover{
    background-color: rgb(40, 40, 40);
    color: #fff;
}

.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-button{
    /* border: 1px solid red; */
    box-sizing: border-box;
}
.w-pc-career-list-screen>.wrapper>.content>.screen.form>.form-container>.form-section.box-button>button{
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-block: 0.8vw;
    padding-inline: 0.7vw;
    width: 22%;
}


.w-pc-career-list-screen>.wrapper>.content>.table-container{
    /* background-color: #043639a3; */
    width: 80%;
    box-sizing: border-box;
}
.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-title{
    height: 10vh;
    box-sizing: border-box;
    padding-inline: 1vw;
    backdrop-filter: blur(8px);
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5vw;
    font-weight: bolder;
    background-color: rgba(0, 0, 0, 0.238);
}
.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-title>.column{
    /* background-color: #fff; */
    width: 25%;
    box-sizing: border-box;
}
.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-title>.column>p{
    font-size: 1.2vw;
}
/* .w-pc-career-list-screen>.wrapper>.content>.table-container>.row-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5vw;
    font-weight: bolder;
} */



.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-value{
    height: 13vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    color: rgb(48, 48, 48);
    padding-inline: 1vw;
    margin-bottom: 0.5vw;
    border-radius: 10px;
}
.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-value>.column {
    /* background-color: #fff; */
    width: 25%;
    box-sizing: border-box;
    cursor: pointer;
}
.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-value>.column.role{
    font-weight: bolder;
    font-size: 1.1vw;
}


.w-pc-career-list-screen>.wrapper>.content>.table-container>.row-value>.column>p {
    font-size: 1vw;
}






/* tab view */
.w-tab-career-list-screen {
    height: 100vh;
    width: 100%;
    position: relative;
    /* padding-top: 10vh; */
}

.w-tab-career-list-screen>.wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.w-tab-career-list-screen>.wrapper>* {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.w-tab-career-list-screen>.wrapper>.background {
    overflow: hidden;
}

.w-tab-career-list-screen>.wrapper>.background>img {
    width: 100%;
}

.w-tab-career-list-screen>.wrapper>.shield {
    background-color: black;
    opacity: 39%;
}

.w-tab-career-list-screen>.wrapper>.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
}

.w-tab-career-list-screen>.wrapper>.content>.screen {
    /* border: 1px solid red; */
    height: 100%;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.tagline {
    height: 100%;
    width: 50vw;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.tagline>h1 {
    font-size: 4vh;
    font-weight: bolder;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.form {
    height: 100%;
    width: 39vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.form>.form {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #043639a3;
    backdrop-filter: blur(8px);
    height: 22vh;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 3vw;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.form>.form>input {
    border: none;
    outline: none;
    border-radius: 10px;
    height: 3vh;
    margin-block: 2vh;
    padding-inline: 1.8vh;
    padding-block: 0.7vh;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.form>.form>input.position {
    width: 15vw;
}

.w-tab-career-list-screen>.wrapper>.content>.screen.form>.form>input.dream-job {
    width: 23vw;
}

/* mobile view */
.w-mob-career-list-screen {
    height: 100vh;
    width: 100%;
    position: relative;
    /* padding-top: 10vh; */
}

.w-mob-career-list-screen>.wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.w-mob-career-list-screen>.wrapper>* {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.w-mob-career-list-screen>.wrapper>.background {
    overflow: hidden;
}

.w-mob-career-list-screen>.wrapper>.background>img {
    height: 100%;
}

.w-mob-career-list-screen>.wrapper>.shield {
    background-color: black;
    opacity: 39%;
}

.w-mob-career-list-screen>.wrapper>.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
}

.w-mob-career-list-screen>.wrapper>.content>.screen {
    /* border: 1px solid red; */
    height: 100%;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.tagline {
    height: 50vh;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.tagline>h1 {
    font-size: 4.5vh;
    font-weight: bolder;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.form {
    height: 90%;
    width:95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 50vh;
    padding-block: 4vh;
    box-sizing: border-box;

}

.w-mob-career-list-screen>.wrapper>.content>.screen.form>.form {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #043639a3;
    backdrop-filter: blur(8px);
    height: 29vh;
    width: 95%;
    border-radius: 10px;
    box-sizing: border-box;
    padding-inline: 5vw;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.form>.form>input {
    border: none;
    outline: none;
    border-radius: 10px;
    height: 6.9vh;
    padding-inline: 1.8vh;
    padding-block: 0vh;
    height: 6vh;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.form>.form>input.position {
    width: 40vw;
}

.w-mob-career-list-screen>.wrapper>.content>.screen.form>.form>input.dream-job {
    width: 72vw;
}