.w-pc-about-us-screen-6 {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-about-us-screen-6>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-about-us-screen-6>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(239, 239, 239);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}
.w-pc-about-us-screen-6>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-about-us-screen-6>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-about-us-screen-6>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-about-us-screen-6>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-about-us-screen-6>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.w-pc-about-us-screen-6>.wrapper>.content>.frame{
    /* border: 1px solid red; */
    box-sizing: border-box;
}
.w-pc-about-us-screen-6>.wrapper>.content>.frame>img {
    height: 40vw;
}


/* mobile */
.w-mob-about-us-screen-6 {
    /* background-color: rgb(226, 226, 226); */
    height: 50vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-about-us-screen-6>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-mob-about-us-screen-6>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(239, 239, 239);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-about-us-screen-6>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-about-us-screen-6>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-about-us-screen-6>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-about-us-screen-6>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-about-us-screen-6>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.w-mob-about-us-screen-6>.wrapper>.content>.frame {
    /* border: 1px solid red; */
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-about-us-screen-6>.wrapper>.content>.frame>img {
    height: 44%;
}