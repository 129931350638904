.w-pc-about-us-screen1 {
    height: 100vh;
    box-sizing: border-box;

}

.w-pc-about-us-screen1>* {
    box-sizing: border-box;
}

.w-pc-about-us-screen1>.wrapper {
    height: 100vh;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-about-us-screen1>.wrapper>.screen {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;  
    overflow: hidden;
}

.w-pc-about-us-screen1>.wrapper>.screen.bg {
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
    /* padding-left: 15vw; */
    box-sizing: border-box;
    overflow: hidden;
    /* background-color: red; */
    /* background: linear-gradient(to right, rgba(255, 255, 255), rgba(255, 255, 255, 0.881)); */
    background-color: rgba(255, 255, 255);
}

.w-pc-about-us-screen1>.wrapper>.screen.bg>img {
    height: 100%;
    width: 100%;
}

.w-pc-about-us-screen1>.wrapper>.screen.shield {
    
    /* opacity: 0%; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, rgba(255, 255, 255) 30%, rgba(255, 255, 255, 0) 70%);
    box-sizing: border-box;
    /* margin-left: 15vw; */
    /* width: 85vw; */
    /* border: 1px solid green; */
    overflow: hidden;
}

.w-pc-about-us-screen1>.wrapper>.screen.content {
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>div {
    padding-left: 5vw;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.tagline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    /* border: 2px solid red; */
    box-sizing: border-box;
    
}
.w-pc-about-us-screen1>.wrapper>.screen.content>.tagline>div>h3.text-capsule{
    font-size: 2vh;
    color: #FFFFFF;
    background-color: #1EB5C0;
    font-family: "CustomPoppinsBold";
    width: 28%;
    text-align: center;
    letter-spacing: 2px;
    padding-block: 9px;
    border-radius: 30px;
}
.w-pc-about-us-screen1>.wrapper>.screen.content>.tagline>h1 {
    font-size: 16vh;
    color: #fff;
    /* font-family: "CustomPoppinsBold"; */
    line-height: 17vh;
    /* margin-bottom: 20vh; */
}
.w-pc-about-us-screen1>.wrapper>.screen.content>.tagline>h1.text-1{
    color: #161616;
}
.w-pc-about-us-screen1>.wrapper>.screen.content>.tagline>h1.text-2 {
    color: #1EB5C0;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.buttons {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-top: 2vh;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.buttons>* {
    margin-inline: 3px;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 30px;
    user-select: none;
    font-size: 19px;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.buttons>.button:hover {
    background-color: #161616;
    color: #f7f7f7;
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 15px;
    padding-inline: 30px;
    background-color: #fff;
    text-align: center;
    color: #0cacb7;
    font-family: "CustomPoppinsBold";
}

.w-pc-about-us-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 15px;
    padding-inline: 30px;
    background-color: #0cacb7;
    text-align: center;
    color: #fff;
    font-family: "CustomPoppinsBold";
}


/* tab */
.w-tab-home-screen1 {
    /* background-color: #2BB2BB; */
    height: 100vh;
    /* width: 100vw; */
    box-sizing: border-box;
}

;

.w-tab-home-screen1>* {
    /* width: 100%; */
    box-sizing: border-box;
}

.w-tab-home-screen1>.wrapper {
    /* background-color: aliceblue; */
    height: 100vh;
    position: relative;
    width: 100%;
}

.w-tab-home-screen1>.wrapper>.screen {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-tab-home-screen1>.wrapper>.screen.bg {
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
}

.w-tab-home-screen1>.wrapper>.screen.bg>img {
    width: 150%;
}

.w-tab-home-screen1>.wrapper>.screen.shield {
    background-color: black;
    opacity: 39%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-tab-home-screen1>.wrapper>.screen.content {
    /* background-color: yellow; */

    display: grid;
    grid-template-columns: 1fr;
}

.w-tab-home-screen1>.wrapper>.screen.content>div {
    /* border: 1px dashed white; */
    padding-inline: 3vw;
}

.w-tab-home-screen1>.wrapper>.screen.content>.tagline {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.w-tab-home-screen1>.wrapper>.screen.content>.tagline>h1 {
    font-size: 6vh;
    line-height: 8vh;
    color: #fff;
    width: 70%;
    /* margin-bottom: 0vh; */
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>* {
    margin-inline: 3px;
    cursor: pointer;
    transition: 0.4s ease;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button:hover {
    background-color: #bdbdbd;
    color: #292828;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 20px;
    padding-inline: 30px;
    background-color: #292828;
    text-align: center;
    color: #fff;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 20px;
    padding-inline: 20px;
    background-color: #07747B;
    text-align: center;
    color: #fff;
}

/* mobile */
.w-mob-home-screen1 {
    /* background-color: #2BB2BB; */
    height: 100vh;
    /* width: 100vw; */
    box-sizing: border-box;
}

;

.w-mob-home-screen1>* {
    /* width: 100%; */
    box-sizing: border-box;
}

.w-mob-home-screen1>.wrapper {
    /* background-color: aliceblue; */
    height: 100vh;
    position: relative;
    width: 100%;
}

.w-mob-home-screen1>.wrapper>.screen {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-mob-home-screen1>.wrapper>.screen.bg {
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
}

.w-mob-home-screen1>.wrapper>.screen.bg>img {
    height: 100%;
}

.w-mob-home-screen1>.wrapper>.screen.shield {
    background-color: black;
    opacity: 39%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-mob-home-screen1>.wrapper>.screen.content {
    /* background-color: yellow; */

    display: grid;
    grid-template-columns: 1fr;
}

.w-mob-home-screen1>.wrapper>.screen.content>div {
    /* border: 1px dashed white; */
    padding-inline: 3vw;
}

.w-mob-home-screen1>.wrapper>.screen.content>.tagline {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.w-mob-home-screen1>.wrapper>.screen.content>.tagline>h1 {
    font-size: 5vh;
    line-height: 7vh;
    color: #fff;
    /* margin-bottom: 0vh; */
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>* {
    margin-inline: 3px;
    cursor: pointer;
    transition: 0.4s ease;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button:hover {
    background-color: #bdbdbd;
    color: #292828;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 20px;
    padding-inline: 30px;
    background-color: #292828;
    text-align: center;
    color: #fff;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 20px;
    padding-inline: 20px;
    background-color: #07747B;
    text-align: center;
    color: #fff;
}