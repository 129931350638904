.w-pc-our-partner {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-our-partner>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-our-partner>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-our-partner>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-our-partner>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-our-partner>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-our-partner>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}
.w-pc-our-partner>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5vw;
    /* background-color: aquamarine; */
}
.w-pc-our-partner>.wrapper>.content.display>h2{
    font-size: 4vw;
    margin-bottom: 1vh;
}
.w-pc-our-partner>.wrapper>.content.display>.grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 20vh;
    max-height: 100vh;
    box-sizing: border-box;
    /* border: 1px solid red; */
    width: 100%;
}
.w-pc-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px dashed darkblue; */
    width: 100%;
    padding: 1vh;
}
.w-pc-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item>.frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* border: 1px dashed red; */
    overflow: hidden;
}
.w-pc-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item>.frame>img{
    width: 60%;
}


/* mobile */
.w-mob-our-partner {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-our-partner>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-mob-our-partner>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-our-partner>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-our-partner>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-our-partner>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-our-partner>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-our-partner>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5vw;
    /* background-color: aquamarine; */
}

.w-mob-our-partner>.wrapper>.content.display>h2 {
    font-size: 5vh;
    margin-bottom: 1vh;
}

.w-mob-our-partner>.wrapper>.content.display>.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 20vh;
    /* max-height: 100vh; */
    box-sizing: border-box;
    /* border: 1px solid red; */
    width: 100%;
}

.w-mob-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px dashed darkblue; */
    width: 100%;
    padding: 1vh;
}

.w-mob-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item>.frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* border: 1px dashed red; */
    overflow: hidden;
}

.w-mob-our-partner>.wrapper>.content.display>.grid-wrapper>.grid-item>.frame>img {
    width: 80%;
}