.w-pc-zillient-screen-details {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-zillient-screen-details>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-zillient-screen-details>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-zillient-screen-details>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-zillient-screen-details>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-zillient-screen-details>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-zillient-screen-details>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    margin-block: 2vw;
}
.w-pc-zillient-screen-details>.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid red; */
}
.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder>
.grid-item{
    box-sizing: border-box;
    padding-inline: 1vw;
    padding-block: 1vw;
}
.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder>.grid-item>.card{
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.244);
    height: 100%;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    /* background-color: red; */
}
.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder>.grid-item>.card.display-col{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-inline: 3vw;
    padding-block: 3vw;
    /* margin-top: 2vw; */
    width: 60vw;
}

.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder>.grid-item>.card.display-col>h3{
    font-size: 2vw;
    text-align: justify;
    margin-bottom: 1.5vw;
}
.w-pc-zillient-screen-details>.wrapper>.content.display>.cardholder>.grid-item>.card.display-col>p {
    font-size: 1.3vw;
    margin-bottom: 0.8vw;
    text-align: justify;
}
