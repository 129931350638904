.w-pc-career-meaningful {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-career-meaningful>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-career-meaningful>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-career-meaningful>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-career-meaningful>.wrapper>.background>.frame>img{
    width: 100%;
    
}

.w-pc-career-meaningful>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-career-meaningful>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}
.w-pc-career-meaningful>.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-pc-career-meaningful>.wrapper>.content.display>.text-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-inline: 3vw;
}
.w-pc-career-meaningful>.wrapper>.content.display>.text-wrapper>h2{
    font-size: 3.7vw;
    font-family: 'CustomPoppinsBold';
    text-align: center;
    line-height: 140%;
    margin-block: 1vw;
    color: rgb(45, 45, 45);
}
.w-pc-career-meaningful>.wrapper>.content.display>.text-wrapper>p{
    font-size: 1.1vw;
    /* font-family: 'CustomPoppinsBold'; */
    text-align: center;
    line-height: 140%;
    margin-block: 0vw;
    color: rgb(45, 45, 45);
    width: 80%;
}
.w-pc-career-meaningful>.wrapper>.content.display>.gallery{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    box-sizing: border-box;
    margin-top: 3.5vw;
}
.w-pc-career-meaningful>.wrapper>.content.display>.gallery>.item{
    /* border: 1px solid red; */
    box-sizing: border-box;
    padding: 0.6vw;
}
.w-pc-career-meaningful>.wrapper>.content.display>.gallery>.item>.card{
    /* border: 1px solid orange; */
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.195);
}
.w-pc-career-meaningful>.wrapper>.content.display>.gallery>.item>.card>.frame{
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-career-meaningful>.wrapper>.content.display>.gallery>.item>.card>.frame>img{
    height: 100%;
}


/* mobile */
.w-mob-career-meaningful {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-career-meaningful>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-mob-career-meaningful>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-career-meaningful>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-career-meaningful>.wrapper>.background>.frame>img {
    width: 100%;

}

.w-mob-career-meaningful>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-career-meaningful>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-career-meaningful>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.w-mob-career-meaningful>.wrapper>.content.display>.text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-inline: 3vw;
}

.w-mob-career-meaningful>.wrapper>.content.display>.text-wrapper>h2 {
    font-size: 3vh;
    font-family: 'CustomPoppinsBold';
    text-align: center;
    line-height: 130%;
    margin-block: 1vw;
    color: rgb(45, 45, 45);
    margin-bottom: 2vh;
}

.w-mob-career-meaningful>.wrapper>.content.display>.text-wrapper>p {
    font-size: 1.5vh;
    /* font-family: 'CustomPoppinsBold'; */
    text-align: justify;
    line-height: 140%;
    margin-block: 0vw;
    color: rgb(45, 45, 45);
    width: 95%;
}

.w-mob-career-meaningful>.wrapper>.content.display>.gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    justify-content: center;
    box-sizing: border-box;
    margin-top: 3vh;
    width: 100%;
    /* background-color: yellow; */
}

.w-mob-career-meaningful>.wrapper>.content.display>.gallery>.item {
    /* border: 1px solid red; */
    box-sizing: border-box;
    padding: 0.6vw;
}

.w-mob-career-meaningful>.wrapper>.content.display>.gallery>.item>.card {
    /* border: 1px solid orange; */
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.195);
}

.w-mob-career-meaningful>.wrapper>.content.display>.gallery>.item>.card>.frame {
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-career-meaningful>.wrapper>.content.display>.gallery>.item>.card>.frame>img {
    height: 100%;
}