.w-pc-world-map {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-world-map>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-world-map>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-world-map>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-world-map>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-world-map>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-world-map>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}
.w-pc-world-map>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-world-map>.wrapper>.content.display>.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    /* border: 1px dashed yellow; */
    padding-inline: 5vw;
}
.w-pc-world-map>.wrapper>.content.display>.wrapper>h2{
    font-size: 3.5vw;
}
.w-pc-world-map>.wrapper>.content.display>.wrapper>.frame{
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 70%;
    /* border: 1px dashed gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-world-map>.wrapper>.content.display>.wrapper>.frame>img{
    height: 120%;
}

/* mobile */
.w-mob-world-map {
    /* background-color: rgb(226, 226, 226); */
    height: 55vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-world-map>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-mob-world-map>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-world-map>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-world-map>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-world-map>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-world-map>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-world-map>.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-world-map>.wrapper>.content.display>.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    /* border: 1px dashed yellow; */
    padding-inline: 1vw;
}

.w-mob-world-map>.wrapper>.content.display>.wrapper>h2 {
    font-size: 3.5vh;
    padding-inline:4vw;
    text-align: center;
    font-family: 'CustomPoppinsBold';
}

.w-mob-world-map>.wrapper>.content.display>.wrapper>.frame {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 50%;
    /* border: 1px dashed gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-world-map>.wrapper>.content.display>.wrapper>.frame>img {
    width: 100%;
}