.w-pc-app-footer{
    height: 39vh;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    justify-content: center;
    background-color: #2BB2BB;
    color: #fff;

}
.w-pc-app-footer>.footer-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    height: 100%;
    /* border: 1px solid red; */
}

.w-pc-app-footer>.footer-group>.anchor-group{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-right: 3vw;
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: 60%;
}
.w-pc-app-footer>.footer-group>.anchor-group>
h3{
    font-size: 3vh;
    /* margin-bottom: 0.3vh; */
}
.w-pc-app-footer>.footer-group>.anchor-group>
p{
    margin-top: 1vh;
    font-size: 1.8vh;
    cursor: pointer;
}
.w-pc-app-footer>.footer-group>.anchor-group.our-office>p {
    margin-top: 1vh;
    font-size: 1.8vh;
    cursor: pointer;
    width: 70%;
}

.w-pc-app-footer>.footer-group.bar-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 100%;
    box-sizing: border-box;
}
.w-pc-app-footer>.footer-group>.image{
    /* background-color: #fff; */
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-app-footer>.footer-group>.image>img{
    width: 70%;
}
.w-pc-app-footer>.footer-group.bar-1>.text-wrapper{
    box-sizing: border-box;
    padding-inline: 3vw;
    margin-top: 2vh;
}
.w-pc-app-footer>.footer-group.bar-1>.text-wrapper>p{
    text-align: center;
}


/* tab view */
.w-tab-app-footer {
    height: 15vh;
    width: 100%;
    display: grid;
    grid-template-columns: 35% 65%;
    align-items: center;
    justify-content: center;
    background-color: #2BB2BB;
    color: #fff;

}

.w-tab-app-footer>.footer-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-tab-app-footer>.footer-group>.anchor-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-inline: 3vw;
}

.w-tab-app-footer>.footer-group>.anchor-group>h3 {
    font-size: 1.5vh;
    /* margin-bottom: 2vh; */
}

.w-tab-app-footer>.footer-group>.anchor-group>p {
    margin-top: 0.5vh;
    font-size: 1vh;
}

.w-tab-app-footer>.footer-group>.image {
    background-color: #fff;
    width: 100%;
    ;
    padding-left: 4vw;
    overflow: hidden;
}

.w-tab-app-footer>.footer-group>.image>img {
    height: 15vh;

}


/* mobile */
.w-mob-app-footer {
    height: 105vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-rows: 20vh 80vh;
    background-color: #2BB2BB;
    color: #fff;
    box-sizing: border-box;
    /* padding-top: 5vh; */

}

.w-mob-app-footer>.footer-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px solid red; */
}



.w-mob-app-footer>.footer-group>.anchor-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
    box-sizing: border-box;
    padding-inline: 30vw;
    margin-block: 2vh;
    /* margin-inline: 2vw; */
}

.w-mob-app-footer>.footer-group>.anchor-group>h3 {
    font-size: 2.5vh;
    margin-bottom: 1vh;
}

.w-mob-app-footer>.footer-group>.anchor-group>p {
    margin-top: 0.4vh;
    font-size: small;
}

.w-mob-app-footer>.footer-group.bar-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
}
.w-mob-app-footer>.footer-group.bar-2 {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.w-mob-app-footer>.footer-group>.image {
    /* background-color: #fff; */
    width: 100%;

    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-app-footer>.footer-group>.image>img {
    width: 70%;
    height: auto;
}

.w-mob-app-footer>.footer-group.bar-1>.text-wrapper {
    box-sizing: border-box;
    padding-inline: 3vw;
    margin-top: 2vh;
}

.w-mob-app-footer>.footer-group.bar-1>.text-wrapper>p {
    text-align: center;
    font-size: small;
}