.w-pc-home-screen1{
    /* background-color: #2BB2BB; */
    height: 100vh;
    /* width: 100vw; */
    box-sizing: border-box;
    
};

.w-pc-home-screen1>*{
    /* width: 100%; */
    box-sizing: border-box;
}

.w-pc-home-screen1>.wrapper{
    /* background-color: aliceblue; */
    height: 100vh;
    position: relative;
    width: 100%;
    /* border: 1px solid red; */
}
.w-pc-home-screen1>.wrapper>.screen{
    position: absolute;
    width: 100%;
    height: 100%;
}
.w-pc-home-screen1>.wrapper>.screen.bg{
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
}
.w-pc-home-screen1>.wrapper>.screen.bg>img{
    width: 100%;
}
.w-pc-home-screen1>.wrapper>.screen.shield {
    background-color: black;
    opacity: 0%;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.w-pc-home-screen1>.wrapper>.screen.content {
    /* background-color: rgb(13, 0, 255); */

    display: grid;
    grid-template-columns: 1fr;
}

.w-pc-home-screen1>.wrapper>.screen.content>div{
    /* border: 1px dashed white; */
    padding-inline: 3vw;
}
.w-pc-home-screen1>.wrapper>.screen.content>.tagline{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 45%;
    /* align-items: end; */
}
.w-pc-home-screen1>.wrapper>.screen.content>.tagline>h1{
    font-size: 8vh;
    color: #fff;
    font-family: "CustomPoppinsBold";
    line-height: 8vh;
    /* margin-bottom: 20vh; */
}
.w-pc-home-screen1>.wrapper>.screen.content>.buttons{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-top: 2vh;
}
.w-pc-home-screen1>.wrapper>.screen.content>.buttons>*{
    margin-inline:3px;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 30px;
    user-select: none;
    font-size: 19px;
}
.w-pc-home-screen1>.wrapper>.screen.content>.buttons>.button:hover{
    background-color: #161616;
    color: #f7f7f7;
}
.w-pc-home-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 13px;
    padding-inline: 30px;
    background-color: #fff;
    text-align: center;
    color:#0cacb7;
    font-family: "CustomPoppinsBold";
    box-sizing: border-box;
    margin-right: 0.8vw;
    
}
.w-pc-home-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 13px;
    padding-inline: 30px;
    background-color: #0cacb7;
    text-align: center;
    color: #fff;
    font-family: "CustomPoppinsBold";
    box-sizing: border-box;
}


/* tab */
.w-tab-home-screen1 {
    /* background-color: #2BB2BB; */
    height: 100vh;
    /* width: 100vw; */
    box-sizing: border-box;

}

.w-tab-home-screen1>* {
    /* width: 100%; */
    box-sizing: border-box;
}

.w-tab-home-screen1>.wrapper {
    /* background-color: aliceblue; */
    height: 100vh;
    position: relative;
    width: 100%;
    /* border: 1px solid red; */
}

.w-tab-home-screen1>.wrapper>.screen {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-tab-home-screen1>.wrapper>.screen.bg {
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
}

.w-tab-home-screen1>.wrapper>.screen.bg>img {
   width: 120%;
   /* height: 100%; */
}

.w-tab-home-screen1>.wrapper>.screen.shield {
    background-color: black;
    opacity: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-tab-home-screen1>.wrapper>.screen.content {
    /* background-color: rgb(13, 0, 255); */

    display: grid;
    grid-template-columns: 1fr;
}

.w-tab-home-screen1>.wrapper>.screen.content>div {
    /* border: 1px dashed white; */
    padding-inline: 3vw;
}

.w-tab-home-screen1>.wrapper>.screen.content>.tagline {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 70%;
    /* align-items: end; */
}

.w-tab-home-screen1>.wrapper>.screen.content>.tagline>h1 {
    font-size: 5vw;
    color: #fff;
    font-family: "CustomPoppinsBold";
    line-height: 6vw;
    /* margin-bottom: 20vh; */
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-top: 2vh;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>* {
    margin-inline: 3px;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 30px;
    user-select: none;
    font-size: 19px;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button:hover {
    background-color: #161616;
    color: #f7f7f7;
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 15px;
    padding-inline: 30px;
    background-color: #fff;
    text-align: center;
    color: #0cacb7;
    font-family: "CustomPoppinsBold";
}

.w-tab-home-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 15px;
    padding-inline: 30px;
    background-color: #0cacb7;
    text-align: center;
    color: #fff;
    font-family: "CustomPoppinsBold";
}


/* mobile */
.w-mob-home-screen1 {
    /* background-color: #2BB2BB; */
    height: 100vh;
    /* width: 100vw; */
    box-sizing: border-box;

}

.w-mob-home-screen1>* {
    /* width: 100%; */
    box-sizing: border-box;
}

.w-mob-home-screen1>.wrapper {
    /* background-color: aliceblue; */
    height: 100vh;
    position: relative;
    width: 100%;
    /* border: 1px solid red; */
}

.w-mob-home-screen1>.wrapper>.screen {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-mob-home-screen1>.wrapper>.screen.bg {
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
}

.w-mob-home-screen1>.wrapper>.screen.bg>img {
    /* width: 110%; */
    height: 100%;
}

.w-mob-home-screen1>.wrapper>.screen.shield {
    background-color: black;
    opacity: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-mob-home-screen1>.wrapper>.screen.content {
    /* background-color: rgb(13, 0, 255); */

    display: grid;
    grid-template-columns: 1fr;
}

.w-mob-home-screen1>.wrapper>.screen.content>div {
    /* border: 1px dashed white; */
    padding-inline: 3vw;
}

.w-mob-home-screen1>.wrapper>.screen.content>.tagline {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 80%;
    /* align-items: end; */
}

.w-mob-home-screen1>.wrapper>.screen.content>.tagline>h1 {
    font-size: 10vw;
    color: #fff;
    font-family: "CustomPoppinsBold";
    line-height: 11vw;
    /* margin-bottom: 20vh; */
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-top: 2vh;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>* {
    margin-inline: 3px;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 30px;
    user-select: none;
    font-size: 4vw;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button:hover {
    background-color: #161616;
    color: #f7f7f7;
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button-1 {
    padding-block: 2.9vw;
    padding-inline: 30px;
    background-color: #fff;
    text-align: center;
    color: #0cacb7;
    font-family: "CustomPoppinsBold";
}

.w-mob-home-screen1>.wrapper>.screen.content>.buttons>.button-2 {
    padding-block: 2.9vw;
    padding-inline: 30px;
    background-color: #0cacb7;
    text-align: center;
    color: #fff;
    font-family: "CustomPoppinsBold";
}

