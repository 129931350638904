
/* SCREEN OUR VALUES */
.w-pc-home-screen-2{
    height: 63vh;
    width: 100%;
    padding-block: 10vh;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    box-sizing: border-box;
}



.w-pc-home-screen-2>
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.w-pc-home-screen-2>.wrapper>h2 {
    font-size: 5vh;
}

.w-pc-home-screen-2>.wrapper>
.cardholder{
    margin-block: 10vh;
    display: grid;
    width: 58%;;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}
.w-pc-home-screen-2>.wrapper>.cardholder>
.grid-item{
    /* border: 1px solid red; */
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-home-screen-2>.wrapper>.cardholder>.grid-item>
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.w-pc-home-screen-2>.wrapper>.cardholder>.grid-item>
.card>img{
    height: 10vh;
    margin-bottom: 1.7vh;
    filter: invert(94%);
}



/* mobile view */
.w-mob-home-screen-2 {
    width: 100%;
    padding-top: 10vh;
    background-color: var(--screen-bg-color);
        color: var(--screen-text-color);
    /* border: 3px solid red; */
    box-sizing: border-box;
    /* background-color: red; */
}

.w-mob-home-screen-2>.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-mob-home-screen-2>.wrapper>h2 {
    font-size: 5vh;
}

.w-mob-home-screen-2>.wrapper>.cardholder {
    margin-block: 5vh;
    display: grid;
    width: 60%;
    grid-auto-rows: 17vh;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}

.w-mob-home-screen-2>.wrapper>.cardholder>.grid-item {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-home-screen-2>.wrapper>.cardholder>.grid-item>.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-mob-home-screen-2>.wrapper>.cardholder>.grid-item>.card>img {
    height: 8vh;
    margin-bottom: 1.7vh;
    filter: invert(94%);
}

/* tab */
.w-tab-home-screen-2 {
    width: 100%;
    padding-block: 10vh;
    /* border: 1px solid red; */
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
}

.w-tab-home-screen-2>.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-tab-home-screen-2>.wrapper>h2 {
    font-size: 3vh;
}

.w-tab-home-screen-2>.wrapper>.cardholder {
    margin-block: 4vh;
    display: grid;
    width: 80%;
    ;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}

.w-tab-home-screen-2>.wrapper>.cardholder>.grid-item {
    /* border: 1px solid red; */
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen-2>.wrapper>.cardholder>.grid-item>.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.w-tab-home-screen-2>.wrapper>.cardholder>.grid-item>.card>h3{
    font-size: 1.3vh;
}

.w-tab-home-screen-2>.wrapper>.cardholder>.grid-item>.card>img {
    height: 5vh;
    margin-bottom: 1.7vh;
    filter: invert(94%);
}