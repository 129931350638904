
/* SCREEN OUR BUSINESS */
.w-pc-home-screen3{
    /* height: 90vh; */
    padding-block: 5vh;
    color: var(--screen-text-color-alt);
    box-sizing: border-box;
    background-color: rgba(245, 245, 245, 0.461);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

}


.w-pc-home-screen3>.wrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */
    /* border: 1px solid red; */
    width: 100%;
    /* padding-inline: 2vw; */
}
.w-pc-home-screen3>.wrapper>h2{
    font-size:7vh;
    font-family: "CustomPoppinsBold";
}
.w-pc-home-screen3>.wrapper>
.cardholder{
    margin-top: 0vw;
    display: grid;
    width: 97%;
    
    grid-template-columns: repeat(auto-fit, 19vw);
    grid-auto-rows: 30vw;
    row-gap: 0vw;
    /* border: 1px solid black; */
    justify-content: center;

}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 18vw; */
    /* background-color: yellow; */
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card{
    /* overflow: hidden; */
    width: 17.5vw;
    height: 25vw;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #0cacb7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-pc-home-screen3>
.wrapper>
.cardholder>
.grid-item>
.card>
.frame{
    border: 1px solid white;
    height: 19.7vw;
    width: 15.6vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vh;
    border-radius: 15px;
       
}

.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>
img{
    height: 100%;
    border-radius: 5px;
    transition: 0.5s ease;
}

.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background-color: #0cacb7; */
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    box-sizing: border-box;
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>img{
    height: 35px;
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.main{
    /* margin-top: 1vh; */
    font-size: 3.5vh;
    font-family: "CustomPoppinsBold";
    /* background-color: red; */
    /* color: #fff; */
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.label{
    font-size: 2.6vh;
    /* font-family: "CustomPoppinsBold"; */
    color:#1D1D1B ;
}
.w-pc-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.5s ease;
}


/* tab */
.w-tab-home-screen3 {
    /* height: 60vw; */
    
    padding-block: 0vh;
    color: var(--screen-text-color-alt);
    box-sizing: border-box;
    background-color: rgba(245, 245, 245, 0.461);
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    justify-content: center;


}


.w-tab-home-screen3>.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */
    width: 100%;
    /* padding-inline: 2vw; */
}

.w-tab-home-screen3>.wrapper>h2 {
    font-size: 6vw;
    font-family: "CustomPoppinsBold";
}

.w-tab-home-screen3>.wrapper>.cardholder {
    /* margin-block: 6vh; */
    display: grid;
    width: 99%;

    grid-template-columns: repeat(auto-fit, 19vw);
    grid-auto-rows: 40vw;
    row-gap: 2vh;
    /* border: 1px solid black; */
    justify-content: center;

}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 18vw; */
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card {
    /* overflow: hidden; */
    width: 96%;
    height: 35vw;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #0cacb7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame {
    /* border: 1px solid red; */
    height: 17vw;
    width: 15vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vh;
    border-radius: 15px;
    /* border: 1px solid black;/ */

}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>img {
    height: 100%;
    border-radius: 5px;
    transition: 0.5s ease;
    
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>img {
    height: 1.8vh;
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.main {
    /* margin-top: 1vh; */
    font-size: 1.4vh;
    font-family: "CustomPoppinsBold";
    /* background-color: red; */
    /* color: #fff; */
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.label {
    font-size: 1.3vh;
    /* font-family: "CustomPoppinsBold"; */
    color: #1D1D1B;
}

.w-tab-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>img:hover {
    height: 110%;
    transition: 0.5s ease;
}


/* mobile */
.w-mob-home-screen3 {
    /* height: 100vh; */

    /* padding-block: 5vh; */
    color: var(--screen-text-color-alt);
    box-sizing: border-box;
    background-color: rgba(245, 245, 245, 0.461);
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: blue; */


}


.w-mob-home-screen3>.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
    /* padding-inline: 2vw; */
    /* background-color: pink; */
    padding-block: 5vh;
    box-sizing: border-box;
}

.w-mob-home-screen3>.wrapper>h2 {
    font-size: 9vw;
    font-family: "CustomPoppinsBold";
}

.w-mob-home-screen3>.wrapper>.cardholder {
    /* margin-top: 4vh; */
    /* display: grid; */
    /* width: 100%; */

    /* grid-template-columns: repeat(auto-fit, 80%); */
    /* grid-auto-rows: 88vw; */
    /* row-gap: 2vh; */
    /* border: 1px solid black; */
    /* justify-content: center; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-inline: 3vh;

}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item{
    /* border: 1px solid red; */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    /* width: 18vw; */
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card {
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #0cacb7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.8vh;
    padding-bottom: 1.5vh;
    padding-inline: 1.5vh;
    box-sizing: border-box;
   
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame {
    /* border: 1px solid red; */
    height: 80%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 3vh; */
    border-radius: 15px;
    /* border: 1px solid black; */
    
    box-sizing: border-box;

}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>img {
    width: 100%;
    border-radius: 5px;
    transition: 0.5s ease;

}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 20%;
    box-sizing: border-box;
    /* padding-block: 1vh; */
    margin-top: 1vh;
    /* background-color: gray; */
}
.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>img{
    height: 4vh;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.main{
    /* margin-top: 1vh; */
    font-size: 4vh;
    font-family: "CustomPoppinsBold";
    /* background-color: red; */
    /* color: #fff; */
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.label>.label-wrapper>p.label{
    font-size: 3vh;
    /* font-family: "CustomPoppinsBold"; */
    color: #1D1D1B;
}

.w-mob-home-screen3>.wrapper>.cardholder>.grid-item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.5s ease;
}
