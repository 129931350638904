.w-pc-home-screen4{
    
    color: var(--screen-text-color);
    padding-bottom: 10vh;
    
}
.w-pc-home-screen4>.section.header{
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 3vw;
    box-sizing: border-box;
    background-color: #22939b;
    /* border: 1px solid red; */
}
.w-pc-home-screen4>.section.header>h2 {
    font-size: 7vh;
        font-family: "CustomPoppinsBold";
}
.w-pc-home-screen4>.section.header>.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2vh;
    /* border: 1px solid red; */
    width: 100%;
}
.w-pc-home-screen4>.section.header>.wrapper>.desc{
    font-size: 3vh;
    width: 40%;
    /* line-height: 4.3vh; */
}
.w-pc-home-screen4>.section.header>.wrapper>.button{
    /* padding-block: 1vh; */
    padding-inline: 3vw;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3.5vh;
    font-weight: 700;
    border-radius: 7px;
}


.w-pc-home-screen4>.section.ladders{
    /* padding-block: 1vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-home-screen4>.section.ladders>.grid-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7vh;
    /* background-color: var(--screen-bg-color); */
}
.w-pc-home-screen4>.section.ladders>.grid-item>h2{
    font-size: 5vh;
    font-weight: bold;
    align-self: start;
    padding-inline: 3vw;
    margin-bottom: 1.5vh;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>div{
    /* border: 1px solid red; */
    height: 80vh;
   
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step{
    display: grid;
    width: 100%;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    grid-template-columns: 60% 40%;
    grid-auto-rows: 80vh;
    align-items: center;
    justify-content: center;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-img>img{
    width: 85%;
    border-radius: 10px;
}

.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-text{
    width: 80%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-text>h2{
    font-size: 6vh;
    margin-bottom: 3vh;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-text>h3 {
    font-size: 3vh;
    margin-bottom: 1vh;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-text>p {
    font-size: 2vh;
}
.w-pc-home-screen4>.section.ladders>.grid-item>.step>.step-text>p.button{
    margin-top: 3vh
}


/* tab view */
.w-tab-home-screen4 {
    padding-top: 5vh;
    color: var(--screen-text-color-alt);
}

.w-tab-home-screen4>.section.header {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-inline: 4vw;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-tab-home-screen4>.section.header>h2 {
    font-size: 5vh;
}

.w-tab-home-screen4>.section.header>.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1vh;
    /* border: 1px solid red; */
    width: 100%;
}

.w-tab-home-screen4>.section.header>.wrapper>.desc {
    font-size: 2vh;
    width: 40%;
    /* line-height: 4.3vh; */
}

.w-tab-home-screen4>.section.header>.wrapper>.button {
    /* padding-block: 1vh; */
    padding-inline: 3.4vw;
    height: 2vh;
    padding-block: 2vh;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3vh;
    font-weight: 700;
}


.w-tab-home-screen4>.section.ladders {
    padding-block: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen4>.section.ladders>.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    /* background-color: var(--screen-bg-color); */
}

.w-tab-home-screen4>.section.ladders>.grid-item>h2 {
    font-size: 3vh;
    font-weight: bold;
    align-self: start;
    padding-inline: 3vw;
    margin-bottom: 1.5vh;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>div {
    /* border: 1px solid red; */
    height: 80vh;

}

.w-tab-home-screen4>.section.ladders>.grid-item>.step {
    display: grid;
    width: 100%;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    grid-template-columns: 55% 45%;
    grid-auto-rows: 30vh;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-img>img {
    width: 85%;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-text {
    width: 90%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-text>h2 {
    font-size: 2.5vh;
    margin-bottom: 0.3vh;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-text>h3 {
    font-size: 1.3vh;
    margin-bottom: 0.3vh;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-text>p {
    font-size: 0.9vh;
}

.w-tab-home-screen4>.section.ladders>.grid-item>.step>.step-text>p.button {
    margin-top: 3vh
}


/* mobile view */
.w-mob-home-screen4 {
    padding-top: 5vh;
    color: var(--screen-text-color-alt);
}

.w-mob-home-screen4>.section.header {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-inline: 3vw;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-mob-home-screen4>.section.header>h2 {
    font-size: 5vh;
}

.w-mob-home-screen4>.section.header>.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    padding-top: 2vh;
    /* border: 1px solid red; */
    width: 100%;
}

.w-mob-home-screen4>.section.header>.wrapper>.desc {
    font-size: 2vh;
    width: 80%;
    padding-right:7vw;
    line-height: 3vh;
}

.w-mob-home-screen4>.section.header>.wrapper>.button {
    /* padding-block: 1vh; */
    width: 20%;
    padding-inline: 2vw;
    /* height: 2vh; */
    padding-block: 1vh;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: self-end;
    font-size: 2vh;
    font-weight: 700;
    margin-top: 0.5vh;
}


.w-mob-home-screen4>.section.ladders {
    padding-block: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-home-screen4>.section.ladders>.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    /* background-color: var(--screen-bg-color); */
}

.w-mob-home-screen4>.section.ladders>.grid-item>h2 {
    font-size: 3vh;
    font-weight: bold;
    align-self: start;
    padding-inline: 3vw;
    margin-bottom: 1.5vh;
}



.w-mob-home-screen4>.section.ladders>.grid-item>.step {
    display: grid;
    background-color: var(--screen-bg-color);
    color: var(--screen-text-color);
    grid-template-columns: 1fr;
    grid-template-rows: 30vh 15vh;
    padding-block: 2vh;
}
/* .w-mob-home-screen4>.section.ladders>.grid-item>.step>*{
    border: 1px solid red;
} */

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-img>img {
    width: 90%;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-text {
    /* width: 90%; */
    /* border: 1px solid red; */

    padding-inline: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-text>h2 {
    font-size: 3vh;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-text>h3 {
    font-size: 1.3vh;
    margin-top: 1vh;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-text>p {
    margin-top: 0.5vh;
    font-size: 0.9vh;
}

.w-mob-home-screen4>.section.ladders>.grid-item>.step>.step-text>p.button {
    margin-top: 0vh
}