.w-pc-zillient-cardholder {
    /* background-color: rgb(226, 226, 226); */
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-pc-zillient-cardholder>.wrapper {
    height: 100%;
    width: 100%;
    /* background-color: wheat; */
    position: relative;
}

.w-pc-zillient-cardholder>.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-zillient-cardholder>.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-zillient-cardholder>.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-zillient-cardholder>.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-zillient-cardholder>.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}
.w-pc-zillient-cardholder>.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder{
    display: grid;
    grid-template-columns: repeat(auto-fit, 30vw);
    grid-auto-rows: 30vw;
    box-sizing: border-box;
    padding-block: 4vh;
    width: 95%;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>
.grid-item{
    box-sizing: border-box;
    padding-inline: 1vw;
    padding-block: 1vw;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card{
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.244);
    height: 100%;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 50% 50%;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 72% 28%;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y>.frame {
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y>.frame>img{
    width: 100%;
}

.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y>.text {
    box-sizing: border-box;
    padding-block:1vw;
    padding-inline:1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.w-pc-zillient-cardholder>.wrapper>.content.display>.cardholder>.grid-item>.card.grid-y>.text>h2{
    font-size: 1.2vw;
    text-overflow: ellipsis;
}