.w-pc-home-screen5-alt {
    /* height: 100vh; */
    /* height: 80vh; */
    width: 100%;
    /* background-color: blue; */
}

.w-pc-home-screen5-alt>.wrapper {
    height: 100vh;
    width: 100%;

    display: grid;
    grid-template-columns: 40% 40%;
    grid-template-rows: 100%;
    padding-inline: 0vw;
    box-sizing: border-box;

    justify-content: center;
    align-items: center;
    /* background-color: blue; */
    
    
}

.w-pc-home-screen5-alt>.wrapper>.section{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
}


.w-pc-home-screen5-alt>.wrapper>.section>.text-wrapper>h2{
    font-size: 8vw;
    /* font-family: "CustomPoppinsBold"; */
    line-height: 8vw;
    margin-bottom: 4vw;
    color: #0a97d9;
}
.w-pc-home-screen5-alt>.wrapper>.section>.text-wrapper>p {
    font-size: 3vh;
    /* font-family: "CustomPoppinsBold"; */
    line-height: 3.5vh;
    /* margin-top: 4vh; */
    padding-block: 4vh;
    padding-inline: 2vw;
    border-radius: 10px;
    width: 30vw;
   background-color: white;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.108);
}

.w-pc-home-screen5-alt>.wrapper>.section>img{
    height: 40vw;
}



/* tab */
.w-tab-home-screen5-alt {
    height: 60vw;
    width: 100%;
}

.w-tab-home-screen5-alt>.wrapper {
    height: 60vw;
    width: 100%;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    padding-inline: 5vw;
    box-sizing: border-box;
    

}

.w-tab-home-screen5-alt>.wrapper>.section {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-home-screen5-alt>.wrapper>.section>.text-wrapper>h2 {
    font-size: 4vw;
    font-family: "CustomPoppinsBold";
    line-height: 5vw;
    color: #22939b;
}

.w-tab-home-screen5-alt>.wrapper>.section>.text-wrapper>p {
    font-size: 3vw;
    /* font-family: "CustomPoppinsBold"; */
    line-height: 3.6vw;
    margin-top: 1vw;
}

.w-tab-home-screen5-alt>.wrapper>.section>img {
    height: 50vw;
}

/* mobile */
.w-mob-home-screen5-alt {
    height: 120vw;
    width: 100%;
    padding-block: 7vw;
}

.w-mob-home-screen5-alt>.wrapper {
    height: 120vw;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30% 70%;
    padding-inline: 5vw;
    box-sizing: border-box;

}

.w-mob-home-screen5-alt>.wrapper>.section {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-mob-home-screen5-alt>.wrapper>.section>.text-wrapper>h2 {
    font-size: 6vw;
    font-family: "CustomPoppinsBold";
    line-height: 6.5vw;
    color: #22939b;
}

.w-mob-home-screen5-alt>.wrapper>.section>.text-wrapper>p {
    font-size: 4.5vw;
    /* font-family: "CustomPoppinsBold"; */
    line-height: 5vw;
    margin-top: 2vw;
}

.w-mob-home-screen5-alt>.wrapper>.section.image{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-mob-home-screen5-alt>.wrapper>.section>img {
    height: 100%;
}