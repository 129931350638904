.w-pc-floating-icon{
    position: fixed;
    bottom: 4vh;
    right: 4vw;
    box-sizing: border-box;
    z-index: 9999999;
  

}

.w-pc-floating-icon>
.image{
    /* border: 1px dashed red; */
    height: 6.2vh;
    width: 6.2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* background-color: #061c1ee7; */
    /* border: 2px solid #fff; */
    /* box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.928); */
    /* box-shadow: 0px 1px 20px rgb(255, 255, 255); */
    transition: 0.3s ease;
    cursor: pointer;
}

.w-pc-floating-icon>
.image:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.569);
}

.w-pc-floating-icon>
.image>
img{
    height: 100%;
    /* filter: invert(90%); */
}


.w-pc-floating-bar{
    position: fixed;
    bottom: 0;
    right: 1vw;
    height: 35vh;
    width: 20vw;
    border: 1px dashed white;
    transform: translateY(100%);
    transition: 0.4s ease;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.w-pc-floating-bar>.wrapper{
    width: 100%;
    height: 100%;
    padding-inline: 1.4vw;
    padding-top: 3vh;
    box-sizing: border-box;
}

.w-pc-floating-bar>
.wrapper>
.close{
    display: flex;
    flex-direction: column;
    align-items: end;
    
}

.w-pc-floating-bar>
.wrapper>
.close>
p{
    font-size: 3.4vh;
    cursor: pointer;
}

.w-pc-floating-bar>
.wrapper>
.text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
}
.w-pc-floating-bar>
.wrapper>
.text-wrapper>
h3{
    margin-bottom: 1vh;
}

.w-pc-floating-bar>
.wrapper>
.buttons{
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
}

.w-pc-floating-bar>
.wrapper>
.buttons>
.button-group{
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.423);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 6vh;
    border-radius: 10px;
    background-color: #061c1ec6;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-pc-floating-bar>
.wrapper>
.buttons>
.button-group:hover{
    transition: 0.3s ease;
    background-color: #061c1ee8;
}

.w-pc-floating-bar>
.wrapper>
.buttons>
.button-group>
img{
    height: 3vh;
    margin-right: 1vw;
}

.w-pc-floating-bar>
.wrapper>
.buttons>
.button-group>
p{
    font-size: 2vh;
}

.w-pc-floating-bar.collapsed{
    transform: translateY(0%);
    transition: 0.4s ease;
    box-shadow: 0px 0px 5px rgba(27, 27, 27, 0.246);
    z-index: 99999990;

}



/* tab */
.w-tab-floating-icon {
    position: fixed;
    bottom: 4vh;
    right: 4vw;
    box-sizing: border-box;


}

.w-tab-floating-icon>.image {
    /* border: 1px dashed red; */
    height: 10vh;
    width: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #061c1ee7;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.59);
    transition: 0.3s ease;
    cursor: pointer;
}

.w-tab-floating-icon>.image:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.784);
}

.w-tab-floating-icon>.image>img {
    height: 5vh;
    filter: invert(90%);
}


.w-tab-floating-bar {
    position: fixed;
    bottom: 0;
    right: 1vw;
    height: 35vh;
    width: 20vw;
    border: 1px dashed white;
    transform: translateY(100%);
    transition: 0.4s ease;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.w-tab-floating-bar>.wrapper {
    width: 100%;
    height: 100%;
    padding-inline: 1.4vw;
    padding-top: 3vh;
    box-sizing: border-box;
}

.w-tab-floating-bar>.wrapper>.close {
    display: flex;
    flex-direction: column;
    align-items: end;

}

.w-tab-floating-bar>.wrapper>.close>p {
    font-size: 3.4vh;
    cursor: pointer;
}

.w-tab-floating-bar>.wrapper>.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-tab-floating-bar>.wrapper>.text-wrapper>h3 {
    margin-bottom: 1vh;
}

.w-tab-floating-bar>.wrapper>.buttons {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
}

.w-tab-floating-bar>.wrapper>.buttons>.button-group {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.423);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 6vh;
    border-radius: 10px;
    background-color: #061c1ec6;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-tab-floating-bar>.wrapper>.buttons>.button-group:hover {
    transition: 0.3s ease;
    background-color: #061c1ee8;
}

.w-tab-floating-bar>.wrapper>.buttons>.button-group>img {
    height: 3vh;
    margin-right: 1vw;
}

.w-tab-floating-bar>.wrapper>.buttons>.button-group>p {
    font-size: 2vh;
}

.w-tab-floating-bar.collapsed {
    transform: translateY(0%);
    transition: 0.4s ease;
    box-shadow: 0px 0px 5px rgba(27, 27, 27, 0.246);
}


/* mobile */
.w-mob-floating-icon {
    position: fixed;
    bottom: 4vh;
    right: 4vw;
    box-sizing: border-box;
    z-index: 9999999;
    

}

.w-mob-floating-icon>.image {
    /* border: 1px dashed red; */
    height: 13vw;
    width: 13vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #061c1ee7;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.59);
    transition: 0.3s ease;
    cursor: pointer;
}

.w-mob-floating-icon>.image:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.784);
}

.w-mob-floating-icon>.image>img {
    height: 100%;
    /* filter: invert(90%); */
}


.w-mob-floating-bar {
    position: fixed;
    bottom: 0;
    right: 0vw;

    /* border: 1px dashed white; */
    transform: translateY(100%);
    transition: 0.4s ease;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-inline: 5vw;
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    z-index: 9999999;
}

.w-mob-floating-bar>.wrapper {
    width: 100%;
    height: 100%;
    padding-inline: 1.4vw;
    padding-top: 3vh;
    box-sizing: border-box;
    /* background-color: red; */
}

.w-mob-floating-bar>.wrapper>.close {
    display: flex;
    flex-direction: column;
    align-items: end;

}

.w-mob-floating-bar>.wrapper>.close>p {
    font-size: 3.4vh;
    cursor: pointer;
}

.w-mob-floating-bar>.wrapper>.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
}

.w-mob-floating-bar>.wrapper>.text-wrapper>h3 {
    margin-bottom: 1vh;
}

.w-mob-floating-bar>.wrapper>.buttons {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
}

.w-mob-floating-bar>.wrapper>.buttons>.button-group {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.423);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 6vh;
    border-radius: 10px;
    background-color: #061c1ec6;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
   
}

.w-mob-floating-bar>.wrapper>.buttons>.button-group:hover {
    transition: 0.3s ease;
    background-color: #061c1ee8;
}

.w-mob-floating-bar>.wrapper>.buttons>.button-group>img {
    height: 3vh;
    margin-right: 1vw;
}

.w-mob-floating-bar>.wrapper>.buttons>.button-group>p {
    font-size: 2vh;
}

.w-mob-floating-bar.collapsed {
    transform: translateY(0%);
    transition: 0.4s ease;
    box-shadow: 0px 0px 5px rgba(27, 27, 27, 0.246);
    width: 100%;
   box-sizing: border-box;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}