.w-pc-business-screen1 {
    height: 60vh;
    width: 100%;
    position: relative;
}

.w-pc-business-screen1>.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.w-pc-business-screen1>.wrapper>* {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-pc-business-screen1>.wrapper>.background {
    overflow: hidden;
}

.w-pc-business-screen1>.wrapper>.background>img {
    width: 100%;
}

.w-pc-business-screen1>.wrapper>.shield {
    background-color: black;
    opacity: 39%;
}

.w-pc-business-screen1>.wrapper>.content {

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding-left: 5vw;
    padding-bottom: 10vh;
    box-sizing: border-box;
    color: var(--screen-text-color);
}

.w-pc-business-screen1>.wrapper>.content>h1 {
    font-size: 10vh;
}

/* tab */
.w-tab-business-screen1 {
    height: 60vh;
    width: 100%;
    position: relative;
}

.w-tab-business-screen1>.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.w-tab-business-screen1>.wrapper>* {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-tab-business-screen1>.wrapper>.background {
    overflow: hidden;
}

.w-tab-business-screen1>.wrapper>.background>img {
    height: 100%;
}

.w-tab-business-screen1>.wrapper>.shield {
    background-color: black;
    opacity: 39%;
}

.w-tab-business-screen1>.wrapper>.content {

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding-left: 5vw;
    padding-bottom: 10vh;
    box-sizing: border-box;
    color: var(--screen-text-color);
}

.w-tab-business-screen1>.wrapper>.content>h1 {
    font-size: 5vh;
}


/* mobile */
.w-mob-business-screen1 {
    height: 100vh;
    width: 100%;
    position: relative;
}

.w-mob-business-screen1>.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.w-mob-business-screen1>.wrapper>* {
    position: absolute;
    width: 100%;
    height: 100%;
}

.w-mob-business-screen1>.wrapper>.background {
    overflow: hidden;
}

.w-mob-business-screen1>.wrapper>.background>img {
    height: 100%;
}

.w-mob-business-screen1>.wrapper>.shield {
    background-color: black;
    opacity: 39%;
}

.w-mob-business-screen1>.wrapper>.content {

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding-left: 6vw;
    padding-bottom: 20vh;
    box-sizing: border-box;
    color: var(--screen-text-color);
}

.w-mob-business-screen1>.wrapper>.content>h1 {
    font-size: 7vh;
}